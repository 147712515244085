<template>
  <div>
    <Banner title="一站式自助服务平台" hint="足不出户，数字证书在线办、实时办、轻松办">
      <img class="banner-bg" style="width:100%;height: 502px;" src="@/assets/image/serviceHall/banner-bg.png" alt="一站式自助服务平台" srcset="">
    </Banner>
    <div class="links main-box">
      <div class="icon-animation-box" @click="goRouter(item.name)" v-for="(item,index) in serves" :key="index">
        <div class="icon-animation icon" :style="{'background-image': `url(${require('@/assets/icons/home/serve-icon-'+(item.name)+'.png')})`}"></div>
        <div class="label">{{item.label}}</div>
        <div class="explain">{{item.explain}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      serves: [
        {
          label: '证书申请',
          name: 'certApply',
          explain: '用户首次申请证书，可以根据需要选择具体项目'
        },
        {
          label: '证书续期',
          name: 'certRenewal',
          explain: '用户证书即将过期时，可以在线申请续期'
        },
        {
          label: '证书解锁/密码重置',
          name: 'certUnlock',
          explain: '当您忘记密码或者Ukey被锁死时，您可以进行介质解锁/密码重置'
        },
        {
          label: '证书变更',
          name: 'certChange',
          explain: '使用Ukey，输入Pin进行验证后，可以编辑需要变更的信息'
        },
        {
          label: '证书补办',
          name: 'certReissue',
          explain: '证书载体（Ukey）发生损坏或者丢失时，可以进行补办'
        },
        {
          label: '证书注销',
          name: 'certCancel',
          explain: '当私钥泄密，Ukey丢失，Ukey损坏时您可以注销'
        },
        {
          label: '证书查询',
          name: 'certQuery',
          explain: '输入证书序列号、证书名称/CN即可查询证书状态'
        },
        {
          label: '文档验证',
          name: 'fileVerify',
          explain: '上传文档，可以对文档的签名信息进行验证'
        },
        {
          label: '下载证书助手',
          name: 'updata',
          explain: '证书续期、解锁、变更、注销等业务都需要下载安装证书助手'
        }, {
          label: '验签报告',
          name: 'verifyReport',
          explain: '对文档的签署信息出具证据报告，具有法律效力'
        }
      ]
    }
  },
  methods: {
    verify (name) {
      this.$bus.$off('afterLogin')
      this.$bus.$on('afterLogin', () => {
        this.verify(name)
      })
      this.$api.getAccountInfo().then(res => {
        if (res.data.verified) {
          this.$router.push({ name: name })
        } else {
          this.$confirm('当前账号未完成管理员实名认证，实名之后才可办理业务。', '提示', {
            confirmButtonText: '实名认证',
            cancelButtonText: '暂不认证',
            type: 'warning'
          }).then(() => {
            this.$router.push({ path: '/personHome' })
          }).catch(() => {

          });
        }
      })
    },
    goRouter (name) {
      if (['certQuery', 'fileVerify'].includes(name)) {
        this.$router.push({ name })
      } else if (['updata'].includes(name)) {
        this.$router.push({ name: 'downloadList', query: { type: '1' } })
      } else {
        this.verify(name)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.links {
  margin: 50px auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  & > div {
    width: 246px;
    text-align: center;
    margin-bottom: 70px;
    margin-right: 105px;
    cursor: pointer;
    &:nth-child(4n) {
      margin-right: 0;
    }
    .icon {
      margin: 0 auto;
      width: 72px;
      height: 72px;
      margin-bottom: 12px;
    }
    .label {
      font-size: 18px;
      color: #303b50;
      line-height: 24px;
      margin-bottom: 12px;
    }
    .explain {
      font-size: 14px;
      color: #aeb5be;
      line-height: 24px;
    }
  }
}
</style>